<template>
  <div>
    <ejs-dialog
      ref="storeCodeSelectPopupDialog"
      header="매장코드 검색"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="890"
      :close="dialogClose"
      :position="{ x: 'center', y: 'center' }"
      :enableResize="false"
      :isModal="true"
    >
      <div class="window storeCodeSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field codename">
                    <div class="title">코드명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          v-model="searchCodeName"
                          @keydown.enter="searchCode"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button button-div="GET" v-on:click.native="searchCode">
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-01">
                    <div class="section-body">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                          :id="'Grid'"
                          ref="Grid"
                          height="383"
                          :allowResizing="true"
                          :allowSelection="true"
                          :allowPaging="true"
                          :pageSettings="{ pageCount: 3, pageSize: 15 }"
                          :dataSource="srcData"
                          :selectionSettings="selectionOptions"
                          :columns="columns"
                          :provides="grid"
                          :allowRowDragAndDrop="true"
                          :rowDropSettings="srcDropOptions"
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-body">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                          :id="'DestGrid'"
                          ref="DestGrid"
                          height="423"
                          :allowResizing="true"
                          :allowSelection="true"
                          :allowPaging="true"
                          :dataSource="destData"
                          :selectionSettings="selectionOptions"
                          :columns="columns"
                          :provides="grid"
                          :allowRowDragAndDrop="true"
                          :rowDropSettings="destDropOptions"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" v-on:click.native="confirmCode">
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" v-on:click.native="dialogClose"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { RowDD, Selection, Page } from "@syncfusion/ej2-vue-grids";

import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "storeCodeSelectPopup",
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      grid: [RowDD, Selection, Page],
      selectedCodes: [],
      animationSettings: { effect: "None" },
      searchCodeName: "",
      srcData: commonCodesGetCommonCode("STORE_CODE"),
      destData: [],
      selectionOptions: { type: "Multiple" },
      srcDropOptions: { targetID: "DestGrid" },
      destDropOptions: { targetID: "Grid" },
      columns: [
        {
          field: "comCode",
          headerText: "상품코드",
          allowEditing: false,
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "comName",
          headerText: "코드명",
          allowEditing: false,
          minWidth: 16,
          textAlign: "left",
        },
      ],
    };
  },
  methods: {
    showPopup(selectedCodes) {
      this.$refs.storeCodeSelectPopupDialog.show();
      this.selectedCodes = selectedCodes;

      this.loadData();
    },
    loadData() {
      this.destData = this.selectedCodes;

      // 선택된 것 제거
      this.filteringSrcData(this.destData);
    },
    searchCode() {
      this.srcData = commonCodesGetCommonCode("STORE_CODE");
      this.srcData = this.srcData.filter((code) =>
        code.comName.includes(this.searchCodeName.trim())
      );

      // 선택된 것 제거
      this.filteringSrcData(this.$refs.DestGrid.getCurrentViewRecords());
    },
    confirmCode() {
      this.$emit("popupConfirmed", this.$refs.DestGrid.getCurrentViewRecords());
      this.$refs.storeCodeSelectPopupDialog.hide();
    },
    dialogClose() {
      this.$emit("popupClosed", null);
    },
    filteringSrcData(currnetDestGridRecords) {
      this.srcData = this.srcData.filter(
        (item) =>
          currnetDestGridRecords.findIndex(
            (destItem) => destItem.comCode === item.comCode
          ) === -1
      );
    },
  },
};
</script>
