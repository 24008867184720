<template>
  <div>
    <ejs-dialog
      ref="productCodePopupDialog"
      :header="title"
      :animationSettings="animationSettings"
      allowDragging="true"
      showCloseIcon="true"
      width="1180"
      height="890"
      :close="dialogClose"
      v-on:mousedown.native="onDialogClicked"
      :position="popupPosition"
      :zIndex="currentzIndex"
      enableResize="true"
      :isModal="$props.isModal"
      :visible="isVisible"
      :cssClass="
        productCode.productCode
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
    >
      <div class="window productCodeDetails">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        {{ title }}
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              v-if="productCode.productCode"
                              v-on:click.native="onDeleteClicked"
                          >
                            삭제
                          </erp-button>
                        </li>
                        <li class="reset">
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              v-on:click.native="onInitClicked"
                          >
                            초기화
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">상품정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <article class="body-article">
                            <div class="article-left">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section
                                :class="[
                                  'article-section',
                                  'section-010101',
                                  'accordion',
                                  { close: !isProductDefaultSectionVisible },
                                ]"
                              >
                                <div class="section-header">
                                  <div class="header-left">
                                    <div
                                      class="header-title"
                                      @click="isProductDefaultSectionVisible = !isProductDefaultSectionVisible"
                                    >
                                      상품 기본정보
                                    </div>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div class="body-data">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              대분류
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="classCode01Dropdown"
                                                      id="classCode01Dropdown"
                                                      v-model="
                                                        productCode.classCode01
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.classCode01Options
                                                      "
                                                      :change="
                                                        onClassCode01Changed
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        classCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              중분류
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="classCode02Dropdown"
                                                      id="classCode02Dropdown"
                                                      v-model="
                                                        productCode.classCode02
                                                      "
                                                      :query="
                                                        productCodeOptions.classCode02Query
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.classCode02Options
                                                      "
                                                      :change="
                                                        onClassCode02Changed
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        classCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              소분류
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="classCode03Dropdown"
                                                      id="classCode03Dropdown"
                                                      v-model="
                                                        productCode.classCode03
                                                      "
                                                      :query="
                                                        productCodeOptions.classCode03Query
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.classCode03Options
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        classCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              상품명
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="productNameInput"
                                                      id="productNameInput"
                                                      name="productNameInput"
                                                      @keydown="
                                                        preventEnterEvent
                                                      "
                                                      v-model="
                                                        productCode.productName
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">상품번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="productNoInput"
                                                      name="productNoInput"
                                                      @keydown="
                                                        preventEnterEvent
                                                      "
                                                      v-model="
                                                        productCode.productNo
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">상품코드</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  {{
                                                    productCode.productCode
                                                  }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              면/과세
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="notaxCodesDropdown"
                                                      name="notaxCodesDropdown"
                                                      v-model="
                                                        productCode.notaxCode
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.notaxCodeOptions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        commonCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">규격</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="standardInput"
                                                      name="standardInput"
                                                      v-model="
                                                        productCode.standard
                                                      "
                                                      @keydown="
                                                        preventEnterEvent
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">단위</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="unitInput"
                                                      name="unitInput"
                                                      v-model="
                                                        productCode.unit
                                                      "
                                                      @keydown="
                                                        preventEnterEvent
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">재고관리</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.invntryFlag
                                                          "
                                                          name="invntryFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.invntryFlag
                                                          "
                                                          name="invntryFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">사용여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.useFlag
                                                          "
                                                          name="useFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.useFlag
                                                          "
                                                          name="useFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니요
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">주방출력</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.kitchenPrtFlag
                                                          "
                                                          name="kitchenPrtFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.kitchenPrtFlag
                                                          "
                                                          name="kitchenPrtFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니요
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">옵션여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.optnFlag
                                                          "
                                                          name="optnFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.optnFlag
                                                          "
                                                          name="optnFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니요
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field colorValue">
                                            <!-- 필수 : required -->
                                            <div class="title">색상값</div>
                                            <div class="content color">
                                              <ul class="row">
                                                <li
                                                  class="item form-group value"
                                                >
                                                  <!-- TODO : null 값일때 투명색 or 하얀색 처리, 값은 그대로 null 값 -->
                                                  <!-- 포커스 : focus -->
                                                  <div class="value">
                                                    <ejs-colorpicker
                                                      v-model="
                                                        productCode.colorValue
                                                      "
                                                      cssClass="body-data-colorpicker"
                                                    ></ejs-colorpicker>
                                                  </div>
                                                </li>
                                                <!-- TODO : 색상 변경 시 HEX코드 여섯자리 뒤 ff가 추가됨 -->
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{ productCode.colorValue }}
                                                </li>
                                                <li
                                                  class="item form-group button"
                                                >
                                                  <ul class="button">
                                                    <li class="red">
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          v-on:click.native="
                                                          setProductNameBackgroudColor(
                                                            '#c03737'
                                                          )
                                                        "
                                                      >
                                                        빨간색
                                                      </erp-button>
                                                    </li>
                                                    <li class="orange">
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          v-on:click.native="
                                                          setProductNameBackgroudColor(
                                                            '#d86632'
                                                          )
                                                        "
                                                      >
                                                        주황색
                                                      </erp-button>
                                                    </li>
                                                    <li class="yellow">
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          v-on:click.native="
                                                          setProductNameBackgroudColor(
                                                            '#e2a429'
                                                          )
                                                        "
                                                      >
                                                        노란색
                                                      </erp-button>
                                                    </li>
                                                    <li class="green">
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          v-on:click.native="
                                                          setProductNameBackgroudColor(
                                                            '#2e7458'
                                                          )
                                                        "
                                                      >
                                                        초록색
                                                      </erp-button>
                                                    </li>
                                                    <li class="blue">
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          v-on:click.native="
                                                          setProductNameBackgroudColor(
                                                            '#3170b3'
                                                          )
                                                        "
                                                      >
                                                        파란색
                                                      </erp-button>
                                                    </li>
                                                    <li class="indigo">
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          v-on:click.native="
                                                          setProductNameBackgroudColor(
                                                            '#5a3dac'
                                                          )
                                                        "
                                                      >
                                                        남색
                                                      </erp-button>
                                                    </li>
                                                    <li class="purple">
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          v-on:click.native="
                                                          setProductNameBackgroudColor(
                                                            '#893bab'
                                                          )
                                                        "
                                                      >
                                                        보라색
                                                      </erp-button>
                                                    </li>
                                                    <!--
                                                    <li class="select">
                                                      <ejs-colorpicker
                                                        v-model="
                                                          productCode.colorValue
                                                        "
                                                        cssClass="body-data-colorpicker"
                                                      ></ejs-colorpicker>
                                                    </li>
                                                    -->
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">바코드</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="brcdInput"
                                                      name="brcdInput"
                                                      v-model="
                                                        productCode.brcd
                                                      "
                                                      @keydown="
                                                        preventEnterEvent
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field account">
                                            <!-- 필수 : required -->
                                            <div class="title">거래처</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="bcncMultiSelect"
                                                      :dataSource="
                                                        productCodeOptions.bcncData
                                                      "
                                                      v-model="
                                                        productCode.bcncId
                                                      "
                                                      :fields="bcncFields"
                                                      :filtering="
                                                        bcncFiltering
                                                      "
                                                      :allowFiltering="true"
                                                      cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">구매형태</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="purchsStlesDropdown"
                                                      v-model="
                                                        productCode.purchsStle
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.purchsStleOptions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        commonCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">구입원가</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-number
                                                      v-model="
                                                        productCode.purchsCost
                                                      "
                                                      v-on:keydown.native="
                                                        preventEnterEvent
                                                      "
                                                    />
                                                    <!-- 숫자만 -->
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">자동입출여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.autoIeFlag
                                                          "
                                                          name="autoIeFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.autoIeFlag
                                                          "
                                                          name="autoIeFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니요
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field remarks">
                                            <!-- 필수 : required -->
                                            <div class="title">비고</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-textarea
                                                      ref="remarksInput"
                                                      id="remarksInput"
                                                      name="remarksInput"
                                                      v-model="
                                                        productCode.remarks
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                              </section>
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section
                                :class="[
                                  'article-section',
                                  'section-010102',
                                  'accordion',
                                  { close: !isProductAdditionalSectionVisible },
                                ]"
                              >
                                <div class="section-header">
                                  <div class="header-left">
                                    <div
                                      class="header-title"
                                      @click="isProductAdditionalSectionVisible = !isProductAdditionalSectionVisible"
                                    >
                                      상품 부가정보
                                    </div>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div class="body-data">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">오픈단가</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.openPriceFlag
                                                          "
                                                          name="openPriceFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.openPriceFlag
                                                          "
                                                          name="openPriceFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">제세금</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.mytaxFlag
                                                          "
                                                          name="mytaxFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.mytaxFlag
                                                          "
                                                          name="mytaxFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">체육기금</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.fundFlag
                                                          "
                                                          name="fundFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.fundFlag
                                                          "
                                                          name="fundFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">영업구분</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="bsnCodeDropdown"
                                                      v-model="
                                                        productCode.bsnCode
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.bsnCodeOptions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        commonCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">홀구분</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="holeDivDropdown"
                                                      v-model="
                                                        productCode.holeDiv
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.holeDivOptions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        commonCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">인원구분</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="perDivDropdown"
                                                      v-model="
                                                        productCode.perDiv
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.perDivOptions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        commonCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">태블릿 오더 여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.tabletOrderUseFlag
                                                          "
                                                          name="tabletOrderUseFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.tabletOrderUseFlag
                                                          "
                                                          name="tabletOrderUseFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">배지코드</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                      ref="badgeCodeDropdown"
                                                      v-model="
                                                        productCode.badgeCode
                                                      "
                                                      :dataSource="
                                                        productCodeOptions.badgeCodeOptions
                                                      "
                                                      :allowFiltering="false"
                                                      :fields="
                                                        commonCodeFields
                                                      "
                                                      cssClass="body-data-dropdown"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">일시품절 여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.soldoutFlag
                                                          "
                                                          name="soldoutFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.soldoutFlag
                                                          "
                                                          name="soldoutFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">관제 사용 여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.cntrlUseFlag
                                                          "
                                                          name="cntrlUseFlag"
                                                          v-bind:value="true"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          예
                                                        </div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="radio"
                                                          v-model="
                                                            productCode.cntrlUseFlag
                                                          "
                                                          name="cntrlUseFlag"
                                                          v-bind:value="false"
                                                        />
                                                        <i></i>
                                                        <div class="label">
                                                          아니오
                                                        </div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field" style="width: calc(100% + 1px)">
                                            <!-- 필수 : required -->
                                            <div class="title">원산지</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-textarea
                                                      ref="origin"
                                                      name="origin"
                                                      v-model="
                                                        productCode.origin
                                                      "
                                                      @keydown="
                                                        preventEnterEvent
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field" style="width: calc(100% + 1px)">
                                            <!-- 필수 : required -->
                                            <div class="title">상품설명</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-textarea
                                                      ref="productDescription"
                                                      name="productDescription"
                                                      v-model="
                                                        productCode.productDescription
                                                      "
                                                      @keydown="
                                                        preventEnterEvent
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div class="article-right">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section class="article-section section-010103">
                                <div class="section-header">
                                  <div class="header-left">
                                    <div class="header-title">상품사진</div>
                                  </div>
                                  <div class="header-right">
                                    <ul class="header-button">
                                      <li class="registration">
                                        <ejs-uploader
                                          ref="productFileUploader"
                                          name="productFileUploader"
                                          :buttons="buttons"
                                          :allowedExtensions="
                                            productFileUploaderExtensions
                                          "
                                          :multiple="false"
                                          maxFileSize="10000000"
                                          :selected="onFileSelect"
                                        ></ejs-uploader>
                                      </li>
                                      <li class="delete">
                                        <erp-button
                                            button-div="DELETE"
                                            :is-icon-custom="true"
                                            @click.native="clearProductFiles"
                                        >
                                          삭제
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div
                                    class="body-photo"
                                    :id="`imagePreviewContainer_${productCode.productCode}`"
                                  >
                                    <!-- 업로드 이미지 conatiner -->
                                    <!-- 기존 이미지 영역 TODO : v-if로 이미지가 없는 경우 출력 안함. url에 업로드된 URL을 넣어 줌 -->
                                    <div
                                      v-if="productCode.imagePath"
                                      class="item"
                                      id="previewImage"
                                      :style="{
                                        backgroundImage:
                                          'url(' + productCode.imagePath + ')',
                                      }"
                                    ></div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </article>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">상품 기타정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <article class="body-article">
                            <div class="article-left">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section class="article-section section-010201">
                                <div class="section-header">
                                  <div class="header-left">
                                    <div class="header-title">
                                      상품 단가정보
                                    </div>
                                  </div>
                                  <div class="header-right">
                                    <ul class="header-button">
                                      <li class="add">
                                        <erp-button
                                            button-div="SAVE"
                                            :is-icon-custom="true"
                                            v-on:click.native="
                                            $refs.priceManagementGrid.addPriceManagement()
                                          "
                                        >
                                          추가
                                        </erp-button>
                                      </li>
                                      <li class="delete">
                                        <erp-button
                                            button-div="DELETE"
                                            :is-icon-custom="true"
                                            v-on:click.native="
                                            $refs.priceManagementGrid.deletePriceManagement()
                                          "
                                        >
                                          삭제
                                        </erp-button>
                                      </li>
                                      <li class="reset">
                                        <erp-button
                                            button-div="GET"
                                            :is-icon-custom="true"
                                            v-on:click.native="
                                            $refs.priceManagementGrid.cancelGrid()
                                          "
                                        >
                                          초기화
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <PriceManagementGrid
                                    ref="priceManagementGrid"
                                  />
                                </div>
                              </section>
                            </div>
                            <div class="article-right">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section class="article-section section-010203">
                                <div class="section-header">
                                  <div class="header-left">
                                    <div class="header-title">
                                      판매매장 목록
                                    </div>
                                  </div>
                                  <div class="header-right">
                                    <ul class="header-button">
                                      <li class="add">
                                        <erp-button
                                            button-div="SAVE"
                                            :is-icon-custom="true"
                                            v-on:click.native="
                                            onStoreSearchPopupOpen()
                                          "
                                        >
                                          추가
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div class="body-list">
                                    <div class="list-outer">
                                      <ul>
                                        <li
                                          v-for="code in productStores"
                                          :key="code.comCode"
                                        >
                                          <div class="title">
                                            {{ code.comName }}
                                          </div>
                                          <div class="content">
                                            <div class="delete">
                                              <erp-button
                                                  button-div="DELETE"
                                                  :is-icon-custom="true"
                                                  v-on:click.native="
                                                  onProductStoreDeleteButtonClicked(
                                                    code.comCode
                                                  )
                                                "
                                              >

                                                삭제
                                              </erp-button>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </article>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
            <store-code-select-popup
              v-if="isStoreCodePopupOpen"
              ref="storeCodePopup"
              @popupClosed="testStoreCodePopupClosed"
              @popupConfirmed="storeCodePopupConfirmed"
            ></store-code-select-popup>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  v-on:click.native="saveProduct"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .body-list .list-outer {height: 87px !important}
body .window.productCodeDetails .windowContent .article-section.section-010201 .body-grid {height: 90px;}
body .window.productCodeDetails .windowContent .body-article .body-article .article-section {height: auto;}
</style>

<script>
import Vue from "vue";
import { orderBy as _orderBy } from "lodash";
import {
  commonCodesGetCommonCode,
  commonCodesGetSortNo
} from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Query } from "@syncfusion/ej2-data";
import {
  getProductDetail,
  postProductCode,
  putProductCode,
  deleteProductCode,
} from "@/api/product";
import PriceManagementGrid from "./PriceManagementGrid";
import StoreCodeSelectPopup from "@/views/common/StoreCodeSelectPopup";
import InputNumber from "@/components/common/InputNumber";
import { validateFormRefs, uploadImage } from "@/utils/formUtil";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "productCodePopup",
  mixins: [commonMixin, confirmDialogMixin],
  props: ["popupKey", "value", "position", "zIndex", "isModal", "isVisible"],
  components: {
    InputTextarea,
    InputText,
    InputNumber,
    StoreCodeSelectPopup,
    PriceManagementGrid,
    ErpButton,
  },
  created() {
    // productCode 값 설정
    this.productCode.productCode = this.$props.popupKey;
  },
  async mounted() {
    // 공통코드 option 설정
    this.productCodeOptions.notaxCodeOptions = commonCodesGetCommonCode(
      "NOTAX_CODE"
    );
    this.productCodeOptions.purchsStleOptions = commonCodesGetCommonCode(
      "PURCHS_STLE"
    );
    this.productCodeOptions.bsnCodeOptions = commonCodesGetCommonCode(
      "BSN_CODE"
    );

    // 영업구분 코드에 "공통" 데이터 추가.
    if (
      this.productCodeOptions.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "%"
      ) === -1
    ) {
      this.productCodeOptions.bsnCodeOptions.unshift({
        comCode: "%",
        comName: "공통",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.productCodeOptions.holeDivOptions = commonCodesGetCommonCode(
      "HOLE_DIV"
    );
    this.productCodeOptions.perDivOptions = commonCodesGetCommonCode("PER_DIV");
    this.productCodeOptions.badgeCodeOptions = commonCodesGetCommonCode("BADGE_CODE");
    if (this.productCodeOptions.badgeCodeOptions.findIndex(item => item.comCode === "") === -1) {
      this.productCodeOptions.badgeCodeOptions.unshift({
        comCode: "",
        comName: "",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    if (this.$props.value) {
      // 수정용 팝업 : BasePopupWrapper를 통해 대중소분류 및 거래처 목록을 설정한 경우
      // 대중소분류 및 거래처 목록 설정 후 상세조회 API 호출
      this.setClassCodesAndBcncList(this.$props.value);
      await this.fetchProductDetail(this.$props.popupKey); // 수정하는 경우에만 값 조회
    } else {
      // 추가 팝업 : 폼 초기화 및 판매단가 설정 초기화
      // 폼 초기화
      this.initValueWhenCreate();

      // 판매단가 설정
      this.$refs.priceManagementGrid.setPriceManagementGridData(
        this.productCode,
        this.productPrices
      );
    }
  },
  async beforeDestroy() {
    if (this.isProductImageUploadedAndNotSaved) {
      await this.deleteProductFile();
    }
  },
  data() {
    return {
      isSaved: false,
      classCodeFields: { text: "className", value: "classCode" },
      commonCodeFields: { text: "comName", value: "comCode" },
      bcncFields: { text: "bcncName", value: "bcncId" },
      buttons: {
        browse: "등록",
      },
      productFileUploaderExtensions: ".png, .jpg, .jpeg, .gif, .bmp",
      confirmPopupId: {
        onSaveCheckInConfirmPopup: 15,
        onSaveCheckInSearch: 20,
      },
      companyCode: "J01",
      productList: [],
      DropDownTreeListData: [],
      searchDropDownTreeListData: [],
      isProductImageUploadedAndNotSaved: false, // 이 화면에서 product image가 업로드 된 후 아직 저장되지 않았는지 여부를 저장. 창을 닫을 때 파일 삭제 처리를 위함
      // Response data 들
      productCode: {
        // API 로부터 받을 response property와 동일하게 설정하여야 2-way binding이 정상 작동함
        productCode: null,
        classCode01: null,
        classCode02: null,
        classCode03: null,
        productName: null,
        productNo: null,
        notaxCode: null,
        standard: null,
        unit: null,
        invntryFlag: null,
        useFlag: null,
        kitchenPrtFlag: null,
        optnFlag: null,
        autoIeFlag: null,
        colorValue: null,
        brcd: null,
        purchsStle: null,
        purchsCost: null,
        remarks: null,
        openPriceFlag: null,
        mytaxFlag: null,
        fundFlag: null,
        bsnCode: null,
        holeDiv: null,
        perDiv: null,
        bcncId: null,
        imageId: null,
        imagePath: null,
        tabletOrderUseFlag: null,
        cntrlUseFlag: null,
        badgeCode: null,
        soldoutFlag: null,
        productDescription: null,
        origin: null,
      }, // 상품코드 상세정보
      productOptions: [],
      productCodeOptions: {
        // dropdownlist option값 등
        classCode01Options: [],
        classCode02Options: [],
        classCode02Query: null,
        classCode03Options: [],
        classCode03Query: null,
        notaxCodeOptions: [],
        bcncData: [],
        purchsStleOptions: [],
        bsnCodeOptions: [],
        holeDivOptions: [],
        perDivOptions: [],
        storeCodes: [],
        priceDives: [],
        badgeCodeOptions: [],
      },
      productCodeBaseValue: {
        // 추가 시에 초기화시킬 productCode 값 TODO 안 쓸 예정
        invntryFlag: 0,
        useFlag: 1,
        kitchenPrtFlag: 0,
        optnFlag: 0,
        autoIeFlag: 0,
        openPriceFlag: 0,
        mytaxFlag: 0,
        fundFlag: 0,
      },
      productOptionCodes: [], // 옵션 상품 List 상품정보 코드
      productStores: [], // 판매 매장 List
      productPrices: [], // 매장별 단가 설정 목록
      testObj: {
        aaa: "adfdsfdsfsdfsdf",
      },
      searchFormProductCode: "",
      searchFormProductName: "",
      // multi window 확인
      windowKey: 0,
      header: "",
      animationSettings: { effect: "None" },
      popupPosition: { X: this.$props.position.x, Y: this.$props.position.y },
      currentzIndex: this.$props.zIndex,
      isProductDefaultSectionVisible: true,
      isProductAdditionalSectionVisible: true,
      isStoreCodePopupOpen: false,
      validateRefList: {
        classCode01Dropdown: { required: true },
        classCode02Dropdown: { required: true },
        classCode03Dropdown: { required: true },
        productNameInput: { required: true, maxLength: 100 },
        productNoInput: { maxLength: 50 },
        notaxCodesDropdown: { required: true },
        standardInput: { maxLength: 50 },
        unitInput: { maxLength: 50 },
        brcdInput: { maxLength: 50 },
        productDescription: { maxLength: 200 },
        origin: { maxLength: 100, },
      },
    };
  },
  computed: {
    title() {
      if (this.productCode.productCode) {
        return "상품코드 상세";
      } else {
        return "상품코드 추가";
      }
    },
  },
  methods: {
    validateFormRefs,
    setClassCodesAndBcncList(value) {
      this.productCodeOptions.classCode01Options = value.classCode01Options;
      this.productCodeOptions.classCode02Options = value.classCode02Options;
      this.productCodeOptions.classCode03Options = value.classCode03Options;
      this.productCodeOptions.bcncData = value.bcncData;
    },
    async fetchProductDetail(productCode) {
      // 수정시에만 조회함
      const { value } = await getProductDetail(productCode);

      // productCode에 remarks, imageId, imagePath, colorValue가 없으면 추가하기
      if (!value.productCode.remarks) {
        value.productCode = {
          remarks: null,
          ...value.productCode,
        };
      }
      if (!value.productCode.imageId) {
        value.productCode = {
          imageId: null,
          ...value.productCode,
        };
      }
      if (!value.productCode.imagePath) {
        value.productCode = {
          imagePath: null,
          ...value.productCode,
        };
      }
      if (!value.productCode.colorValue) {
        value.productCode = {
          colorValue: null,
          ...value.productCode,
        };
      }

      // 데이터 설정
      this.productCode = value.productCode;

      // 상품 단가 정보
      this.productPrices = _orderBy(value.productPrices.map(data => {
        return {
          ...data,
          priceDivSortNo: commonCodesGetSortNo("PRICE_DIV", data.priceDiv)
        };
      }), ["applyDate", "priceDivSortNo"], ["desc", "asc"]);

      // 판매단가 설정
      this.$refs.priceManagementGrid.setPriceManagementGridData(
        this.productCode,
        this.productPrices
      );

      // 판매매장 설정
      this.productStores = value.productStores;

      // 옵션상품 설정
      this.productOptions = value.productOptions;
    },
    async showPopup(productCode, data) {
      this.setClassCodesAndBcncList(data);
      this.$refs.classCode01Dropdown.focusIn();
      if (productCode) {
        this.productCode.productCode = productCode;
        try {
          await this.fetchProductDetail(this.productCode.productCode);
        } catch (e) {
          console.log(e); // 상품 정보 없을 경우, 대분류 드롭 리스트에 포커스 주기 위해 필요.
        }
      }
      this.$refs.productCodePopupDialog.show();
      this.$refs.classCode01Dropdown.focusIn();
    },
    testStoreCodePopupClosed() {
      this.isStoreCodePopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    storeCodePopupConfirmed(selectedStoreCodes) {
      this.productStores = selectedStoreCodes.slice(0);
    },
    preventEnterEvent(event) {
      // TODO : mixin 또는 공통모듈로
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    initValueWhenCreate() {
      // TODO : 공통모듈화
      // 면, 과세 - combobox
      let notaxDefaultOption = this.productCodeOptions.notaxCodeOptions.find(
        (options) => options.defaultFlag === true
      );
      if (notaxDefaultOption) {
        this.productCode.notaxCode = notaxDefaultOption.comCode;
      } else {
        this.productCode.notaxCode = null;
      }

      // 재고관리 - radio
      this.productCode.invntryFlag = true;

      // 사용여부 - radio
      this.productCode.useFlag = true;

      // 주방출력 - radio
      this.productCode.kitchenPrtFlag = false;

      // 옵션여부 - radio
      this.productCode.optnFlag = false;

      // 자동입출여부 - radio
      this.productCode.autoIeFlag = false;

      // 구매형태 - combobox
      let purchsStleDefaultOption = this.productCodeOptions.purchsStleOptions.find(
        (options) => options.defaultFlag === true
      );
      if (purchsStleDefaultOption) {
        this.productCode.purchsStle = purchsStleDefaultOption.comCode;
      } else {
        this.productCode.purchsStle = null;
      }

      // 오픈단가 - radio
      this.productCode.openPriceFlag = false;

      // 제세금 - radio
      this.productCode.mytaxFlag = false;

      // 체육기금 - radio
      this.productCode.fundFlag = false;

      // 태블릿 오더 여부 - radio
      this.productCode.tabletOrderUseFlag = false;

      // 관제 사용 여부 - radio
      this.productCode.cntrlUseFlag = false;

      // 일시품절 여부 - radio
      this.productCode.soldoutFlag = false;

      // 영업구분 - combobox
      // 상품코드 상세 팝업만 영업구분은 공통코드 데이터의 default 셋팅된 데이터를 기본값으로 하지않고
      // "%" : 공통이란 데이터를 기본값으로 사용한다.
      this.productCode.bsnCode = "%";

      // 홀구분 - combobox
      let holeDivDefaultOption = this.productCodeOptions.holeDivOptions.find(
        (options) => options.defaultFlag === true
      );
      if (holeDivDefaultOption) {
        this.productCode.holeDiv = holeDivDefaultOption.comCode;
      } else {
        this.productCode.holeDiv = null;
      }

      // 인원구분 - combobox
      let perDivDefaultOption = this.productCodeOptions.perDivOptions.find(
        (options) => options.defaultFlag === true
      );
      if (perDivDefaultOption) {
        this.productCode.perDiv = perDivDefaultOption.comCode;
      } else {
        this.productCode.perDiv = null;
      }
    },
    onClassCode01Changed() {
      this.productCodeOptions.classCode02Query = new Query().where(
        "parentCode",
        "equal",
        this.$refs.classCode01Dropdown.ej2Instances.value
      );
    },
    onClassCode02Changed() {
      this.productCodeOptions.classCode03Query = new Query().where(
        "parentCode",
        "equal",
        this.$refs.classCode02Dropdown.ej2Instances.value
      );
    },
    bcncFiltering(e) {
      // TODO : 공통 모듈화
      let searchData = this.productCodeOptions.bcncData;
      if (e.text === "") {
        e.updateData(searchData);
      } else {
        let query = new Query().select(["bcncId", "bcncName"]);
        query =
          e.text !== ""
            ? query.where("bcncName", "contains", e.text, true)
            : query;
        e.updateData(searchData, query);
      }
    },
    async onFileSelect(args) {
      const file = args.event.target.files[0];

      const uploadResponse = await uploadImage(
          this.productCode.imageId,
          "PRODUCT",
          args.filesData,
          file
      );
      if (uploadResponse) {
        this.productCode.imageId = uploadResponse.imageId;
        this.productCode.imagePath = uploadResponse.imageURL;
        this.isProductImageUploadedAndNotSaved = true;
      }

      this.$refs.productFileUploader.clearAll(); // 다시 upload 하지 않도록 clear
    },
    dialogClose() {
      this.$emit(
        "popupEvent",
        "popupClosed",
        this.$props.popupKey,
        this.$props.value
      );
      if (this.isSaved) {
        this.$emit("popupEvent", "popupReload");
      }
    },
    onDialogClicked() {
      this.$emit(
        "popupEvent",
        "popupClicked",
        this.$props.popupKey,
        this.$props.value
      );
    },
    onConfirmPopupCancelClicked(popupId) {
      // confirm popup 이벤트 핸들링 처리. Open시 넣었던 popupId를 기준으로 분기함
      switch (popupId) {
        case this.confirmPopupId.onSaveCheckInConfirmPopup: // 취소 시 URL 변경
          if (this.productCode.productCode) {
            this.$router.push({
              name: "sampleProductCodeAddModified",
              params: { productCode: this.productCode.productCode },
            });
          } else {
            this.$router.push({ name: "sampleProductCodeAddModified" });
          }
          break;
        default:
          break;
      }
    },
    onStoreSearchPopupOpen() {
      this.isStoreCodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.storeCodePopup.showPopup(this.productStores.slice(0));
      });
    },
    onProductStoreDeleteButtonClicked(comCode) {
      this.productStores.splice(
        this.productStores.findIndex((store) => store.comCode === comCode),
        1
      );
    },
    getDataFields(item) {
      return item.name.toUpperCase() === this.findDataFieldName.toUpperCase();
    },
    setDropDownListName(source, datafield, data) {
      const dataFieldName = datafield + "_DROPDOWNLIST_VALUE_NAME";
      this.findDataFieldName = dataFieldName;
      const findIndex = source.datafields.findIndex(this.getDataFields);
      if (findIndex >= 0) {
        source.datafields[findIndex].values.source = data;
      }
    },
    setProductNameBackgroudColor(rgb) {
      if (this.productCode.colorValue === undefined) {
        Vue.set(this.productCode, "colorValue", rgb); // this.productCode 의 변화 체크 기능(watch)을 위해서 설정함
      } else {
        this.productCode.colorValue = rgb;
      }
    },
    formInit() {
      // 대중소분류 초기화 및 원래값으로 돌리기 TODO : 수정 팝업인 경우, 원래값으로 돌리기 처리
      this.productCode.classCode01 = null;

      // default 설정
      this.initValueWhenCreate();

      // 기타 폼 초기화
      this.productCode.productName = null;
      this.productCode.productNo = null;
      this.productCode.standard = null;
      this.productCode.unit = null;
      this.productCode.colorValue = null;
      this.productCode.brcd = null;
      this.productCode.bcncId = null;
      this.productCode.purchsCost = null;
      this.productCode.remarks = null;
      this.productCode.productDescription = null;
      this.productCode.origin = null;

      // 옵션상품 및 판매매장 초기화
      this.productOptions = [];
      this.productStores = [];
    },
    onInitClicked() {
      this.formInit();
    },
    async saveProduct() {
      if (!this.validate()) {
        return;
      }
      if (!this.$refs.priceManagementGrid.isGridValidated()) {
        return;
      }

      let data = {
        productCode: this.productCode,
        productOptions: this.productOptions.map(
          (product) => product.productCode
        ),
        productStores: this.productStores.map((store) => store.comCode),
        productPrice: this.$refs.priceManagementGrid.getGridBatchChanges(),
      };

      if (!data.productCode.productCode) {
        // 추가
        const { value } = await postProductCode(data);
        this.productCode.productCode = value.productCode;
      } else {
        // 수정
        await putProductCode(data.productCode.productCode, data);
      }
      this.isSaved = true;
      this.isProductImageUploadedAndNotSaved = false;

      await this.fetchProductDetail(this.productCode.productCode); // 상세 재조회
      this.infoToast(this.$t("main.popupMessage.saved"));
    },
    async onDeleteClicked() {
      if (!(await this.confirm("삭제하시겠습니까?"))) {
        return;
      }
      await deleteProductCode(this.productCode.productCode);
      this.infoToast(this.$t("main.popupMessage.deleted"));
      this.isSaved = true;
      this.$refs.productCodePopupDialog.hide();
    },
    onCloseClicked() {
      this.$refs.productCodePopupDialog.hide();
    },
    async clearProductFiles() {
      this.$refs.productFileUploader.clearAll();

      if (this.productCode.imageId && this.productCode.imagePath) {
        await this.deleteProductFile();
      }
    },
    async deleteProductFile() {
      await GolfErpAPI.deleteImage(this.productCode.imageId);
      this.productCode.imageId = null;
      this.productCode.imagePath = null;
      this.isProductImageUploadedAndNotSaved = false;
    },
    /**
     *
     * @return {boolean}
     */
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
