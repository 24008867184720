<template>
  <ejs-grid-wrapper
      v-if="this.storeCodes.length > 0 && this.priceDives.length > 0"
      ref="priceManagementGrid"
      :provides="priceManagementGridOptions.provides"
      :columns="priceManagementGridOptions.columns"
      :dataSource="productPrices"
      :validationModification="false"
      :enableHover="false"
      :selectionSettings="priceManagementGridOptions.selectionSettings"
      :editSettings="priceManagementGridOptions.editSettings"
      :validationRules="priceManagementGridOptions.validationRules"
      :isNOColumnDisplay="false"
      @batchDelete="priceGridbatchDelete"
      @queryCellInfo="gridCustomiseCell"
      @headerCellInfo="gridHeaderCellInfo"
  />
</template>

<script>
import { getTodayNavigationDate, DATE_FORMAT_YYYY_MM_DD } from '@/utils/date';
import { Edit, Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "ProductCodePriceManagementPopup",
  mixins: [confirmDialogMixin],
  components: {
    EjsGridWrapper,
  },
  mounted() {
    this.storeCodes.unshift({
      comCode: "%",
      comName: "전체",
      codeAbrv: "",
      defaultFlag: false,
    });
  },
  data() {
    return {
      productCode: {},
      productPrices: [],
      storeCodes: commonCodesGetCommonCode("STORE_CODE"),
      priceDives: commonCodesGetCommonCode("PRICE_DIV"),
      gridSelectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      formatOptions: { type: "date", format: "yyyy-MM-dd" },
      priceGridToolbar: ["Add", "Delete"],
    };
  },
  computed: {
    priceManagementGridOptions() {
      return {
        provides: [Edit, Resize, ForeignKey],
        selectionSettings: {
          type: "Multiple",
          mode: "Both",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "priceId",
            allowEditing: false,
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "applyDate",
            headerText: "적용일자",
            type: "string",
            minWidth: 16,
            width: 120,
            isDateType: true,
          },
          {
            field: "storeCode",
            headerText: "매장",
            type: "string",
            minWidth: 16,
            width: 120,
            editType: "dropdownedit",
            isCommonCodeField: true,
            dataSource: this.storeCodes,
            edit: { params: { sortOrder: "none" } },
          },
          {
            field: "priceDiv",
            headerText: "단가구분",
            type: "string",
            minWidth: 16,
            width: 90,
            editType: "dropdownedit",
            isCommonCodeField: true,
            dataSource: this.priceDives,
            edit: { params: { sortOrder: "none" } },
          },
          {
            field: "price1",
            headerText: "단가1",
            type: "string",
            minWidth: 16,
            width: 90,
            isNumericType: true,
            textAlign: "right",
          },
          {
            field: "price2",
            headerText: "단가2",
            type: "string",
            minWidth: 16,
            width: 90,
            isNumericType: true,
            textAlign: "right",
          },
          {
            field: "svcFlag",
            headerText: "봉사료",
            type: "boolean",
            minWidth: 16,
            width: 90,
            editType: "booleanedit",
            textAlign: "center",
            displayAsCheckBox: true,
          },
          {
            field: "priceChghy",
            headerText: "변경사유",
            type: "string",
            minWidth: 16,
          },
        ],
        validationRules: {
          applyDate: {
            required: true,
            dateFormat: true,
          },
          storeCode: { required: true },
          priceDiv: { required: true },
          priceChghy: { maxLength: 50 },
        },
      };
    },
  },
  methods: {
    cancelGrid() {
      this.$refs.priceManagementGrid.batchCancel();
    },
    priceGridbatchDelete() {
      this.$refs.priceManagementGrid.clearSelection();
    },
    setPriceManagementGridData(productCode, productPrices) {
      this.productCode = productCode;
      this.productPrices = productPrices;
    },
    async addPriceManagement() {
      const now = await getTodayNavigationDate(DATE_FORMAT_YYYY_MM_DD);
      this.$refs.priceManagementGrid.addRecord({
        priceId: null,
        applyDate: now,
        storeCode: null,
        priceDiv: null,
        price1: 0,
        price2: 0,
        svcFlag: false,
        priceChghy: null,
      });
    },
    deletePriceManagement() {
      this.$refs.priceManagementGrid.deleteRecord();

      if (
          !this.$refs.priceManagementGrid.dataSource.length &&
          !this.$refs.priceManagementGrid.element.getElementsByClassName(
              "e-insertedrow"
          ).length
      ) {
        this.$refs.priceManagementGrid.renderEmptyRow();
      }
    },
    gridCustomiseCell(args) {
      // 수정영역 셀 처리
      if (
          args.column.field === "applyDate" ||
          args.column.field === "storeCode" ||
          args.column.field === "priceDiv" ||
          args.column.field === "price1" ||
          args.column.field === "price2" ||
          args.column.field === "svcFlag" ||
          args.column.field === "priceChghy" ||
          args.column.field === "check"
      ) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    gridHeaderCellInfo(args) {
      if (
          args.cell.column.field === "applyDate" ||
          args.cell.column.field === "storeCode" ||
          args.cell.column.field === "priceDiv"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    getGridBatchChanges() {
      return this.$refs.priceManagementGrid.getBatchChanges();
    },
    isGridValidated() {
      return this.$refs.priceManagementGrid.validate();
    },
  },
};
</script>
